<template>
  <div>
    <Navigation />

    <div class="py-2 px-5">
      <DashboardTitle class="mb-2 mt-1"></DashboardTitle>

      <v-row>
        <v-col cols="12" md="4">
          <BuscadorSolicitudes
            :currentFilters="filtros"
            :loading="loading"
            @searchUpdated="getSolicitudes"
          ></BuscadorSolicitudes>
        </v-col>
        <v-col cols="8">
          <v-row dense>
            <v-col cols="12" md="6">
              <SolicitudesTipoGraphic
                :solicitudes="todasLasSolicitudes"
                @searchUpdated="getSolicitudes"
              ></SolicitudesTipoGraphic>
            </v-col>
            <v-col cols="12" md="6">
              <SolicitudesStatusGraphic
                :solicitudes="todasLasSolicitudes"
                @searchUpdated="getSolicitudes"
              ></SolicitudesStatusGraphic>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-toolbar dense flat dark color="primary">
                  <v-toolbar-title>
                    <span>Resultados de la búsqueda</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <download-csv
                    v-if="puedeCrear"
                    name="Solicitudes.csv"
                    :data="downloadData"
                  >
                    <v-btn color="#fff" outlined>
                      <v-icon small class="mr-2">
                        mdi-file-excel
                      </v-icon>
                      <span>Descargar</span>
                    </v-btn>
                  </download-csv>
                </v-toolbar>
                <v-card-text>
                  <TablaSolicitudes
                    :loading="loading"
                    :solicitudes="solicitudes"
                  ></TablaSolicitudes>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BuscadorSolicitudes from "./BuscadorSolicitudes.vue";
import TablaSolicitudes from "./TablaSolicitudes.vue";
import SolicitudesTipoGraphic from "./SolicitudesTipo.graphic.vue";
import SolicitudesStatusGraphic from "./SolicitudesStatus.graphic.vue";

import Navigation from "../../../../components/Nav.vue";
import DashboardTitle from "./DashboardTitle.vue";

import { mainAxios } from "../../../../mainAxios";
import moment from "moment";

export default {
  components: {
    Navigation,
    DashboardTitle,
    BuscadorSolicitudes,
    TablaSolicitudes,
    SolicitudesTipoGraphic,
    SolicitudesStatusGraphic,
  },

  data() {
    return {
      loading: false,
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      solicitudes: [],
      todasLasSolicitudes: {},
      filtros: {},
    };
  },

  computed: {
    puedeCrear() {
      const rolesValidos = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "MANAGER",
        "ADMINAGENT",
        "DIRECTO",
        "OPERACIONES",
        "OPERADOR",
        "MESADECONTROL",
      ];
      return !!rolesValidos.find((e) => e == this.rol);
    },

    downloadData() {
      return this.solicitudes.map((element) => ({
        "No. Solicitud": element.id,
        Titulo: element.titulo ? element.titulo : "No Disponible",
        "Estatus de la Solicitud": element.status
          ? element.status
          : "No Disponible",
        Origen: element.origen ? element.origen : "No Disponible",
        Tipo: element.tipo ? element.tipo : "No Disponible",
        "Fecha de Creación": element.fechaCreacion
          ? moment
              .utc(element.fechaCreacion)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm:ss")
          : "No Disponible",
        Ramo: element.categoria ? element.categoria : "No Disponible",
        Agente: element.nombreAgente
          ? element.nombreAgente
          : "No Disponible",
        "Nombre del Cliente":
          `${element.nombre ? element.nombre : ""}` +
          " " +
          `${element.apellidoPaterno ? element.apellidoPaterno : ""}` +
          " " +
          `${element.apellidoMaterno ? element.apellidoMaterno : ""}`,
        Ejecutivo: element.nombreEjecutivo
          ? element.nombreEjecutivo
          : "No Disponible",
      }));
    },
  },

  mounted() {
    this.getTodasLasSolicitudes();
  },

  methods: {
    getSolicitudes(body) {
      if (!body.status && body.status !== 0) body.status = 8;
      this.loading = true;
      mainAxios
        .post(
          `/v1/solicitud/list/${localStorage.agenteUuid}`,
          body,
          this.requestConfig
        )
        .then(({ data }) => {
          this.loading = false;
          if (data.length) this.solicitudes = data;
          else this.solicitudes = [];
          this.filtros = body;
        });
    },

    getTodasLasSolicitudes() {
      mainAxios
        .get(`/v1/solicitud/info/status/1`, this.requestConfig)
        .then(({ data }) => {
          this.todasLasSolicitudes = data;
        });
    },
  },
};
</script>
