var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.solicitudes,"headers":_vm.headers,"loading":_vm.loading,"no-data-text":"Sin solicitudes para mostrar","no-results-text":"Sin resultados para mostrar","loading-text":"Cargando...","footer-props":{
      'items-per-page-all-text': 'Todos',
      'items-per-page-text': 'Elementos por página',
    }},scopedSlots:_vm._u([{key:"item.cumpleSla",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.cumpleSla == 1 ? "Sí cumple" : "No cumple"))])]}},{key:"item.ver",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.verSolicitud(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.operadorId",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOperadorNombre(item.operadorId))+" ")]}},{key:"item.nombreAgente",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAgenteNombre(item.nombreAgente))+" ")]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [(itemsLength)?_c('div',[_vm._v(" Mostrando "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" solicitudes ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }