<template>
  <div>
    <v-card outlined>
      <v-card-title>Solicitudes por Tipo</v-card-title>
      <canvas id="pie-chart" ref="pieChart"></canvas>
    </v-card>
  </div>
</template>

<script>
import Chart from "chart.js";
import Vue from "vue";

const eventBus = new Vue();

export default {
  props: {
    solicitudes: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      chartData: {
        type: "pie",
        data: {
          labels: [
            "Cotización",
            "Emisión",
            "Renovación",
            "Endoso A y D",
            "Endoso B",
            "Facturacion",
            "Cancelación",
            "Otro",
          ],
          datasets: [
            {
              data: [0, 0, 0, 0, 0, 0, 0, 0],
              backgroundColor: [
                "#4875ca",
                "#f67d2d",
                "#a1a1a1",
                "#ffc616",
                "#5e9edb",
                "#5e9edb",
                "#a64c14",
                "#21417b",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          legend: {
            position: "bottom",
          },
          interaction: {
            mode: "index",
            axis: "y",
          },
        },
        plugins: [
          {
            id: "myEventCatcher",
            beforeEvent(chart, args) {
              if (args.type == "click")
                eventBus.$emit(
                  "clicked",
                  chart.getElementAtEvent(args)[0]._model.label
                );
            },
          },
        ],
      },
    };
  },

  watch: {
    solicitudes() {
      this.loadChart();
    },
  },

  mounted() {
    this.loadChart();
  },

  methods: {
    loadChart() {
      if (!this.solicitudes.Tipos) return;
      this.setChartData();
      const canva = this.$refs.pieChart;

      this.$nextTick(() => {
        new Chart(canva, this.chartData);
        eventBus.$on("clicked", (selectedItem) => {
          this.$emit("searchUpdated", {
            tipo: this.getPayload(selectedItem),
          });
        });
      });
    },

    setChartColors() {
      this.chartData.data.labels.forEach(() =>
        this.chartData.data.datasets[0].backgroundColor.push(
          this.$randomColor()
        )
      );
    },

    setChartData() {
      Object.keys(this.solicitudes.Tipos).forEach((tipo) => {
        const index = this.chartData.data.labels.findIndex(
          (label) => label == tipo
        );
        this.chartData.data.datasets[0].data[index] = this.solicitudes.Tipos[
          tipo
        ];
      });
    },

    getPayload(selectedItem) {
      return selectedItem;
    },
  },
};
</script>
