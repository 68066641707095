<template>
  <div>
    <v-data-table
      :items="solicitudes"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin solicitudes para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template #item.cumpleSla="{ item }">
        <span>{{ item.cumpleSla == 1 ? "Sí cumple" : "No cumple" }}</span>
      </template>
      <template #item.ver="{ item }">
        <v-btn icon x-small @click="verSolicitud(item.id)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>

      <template #item.operadorId="{ item }">
          {{ getOperadorNombre(item.operadorId) }}
      </template>
      <template #item.nombreAgente="{ item }">
          {{ getAgenteNombre(item.nombreAgente) }}
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
const requestConfig = {
  headers: {
    Authorization: "Bearer " + localStorage.agenteAccessToken,
  },
};
import { mainAxios } from "../../../../mainAxios";

export default {
  props: {
    solicitudes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: "Número",
          value: "id",
        },
        {
          text: "Nombre de la Solicitud",
          value: "titulo",
        },
        {
          text: "Agente",
          value: "nombreAgente",
        },
        {
          text: "Estado",
          value: "status",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {
          text: "Categoría",
          value: "categoria",
        },
        {
          text: "Operador",
          value: "operadorId",
        },
        {
          text: "Fecha de Creación",
          value: "fechaCreacion",
        },
        {
          text: "SLA",
          value: "cumpleSla",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
      agentes:[],
      operadoresInbound: [],
      operadores: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,

    };
  },

  computed:{
    tipoOperadores(){
      if(this.rol == 'ADMIN')
        return [...this.operadoresInbound, ...this.operadores]
      if(this.rol.includes('INBOUND'))
        return this.operadoresInbound
      else
        return this.operadores
    }
  },

  methods: {
    verSolicitud(id) {
      this.$router.push(`/seguimiento-solicitud/${id}`);
    },
    getOperadorNombre(id) {
      if(this.tipoOperadores)
        return this.tipoOperadores.find(e => e.id == id) ? this.tipoOperadores.find(e => e.id == id).nombreCompleto : 'No disponible'
      else
        return 'No disponible'
    },
    getAgenteNombre(name){
      return name ?? 'No disponible'
    },

    getOperadores() {
      mainAxios.get("/v1/agentes/operador", requestConfig).then(({ data }) => {
        this.operadores = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },

    getAgentes() {
      mainAxios.get("/v1/agente/list", requestConfig).then(({ data }) => {
        data.forEach((e) => {
         if(e.rol == 'AGENT'){
          this.agentes.push({
            correo: e.correo,
            nombreCompleto: e.nombreCompleto,
            id: e.id,
            uuid: e.uuid,
            rol: e.rol,
          })
         }
         if(e.rol == 'OPERADORINBOUND'){
          this.operadoresInbound.push({
            correo: e.correo,
            nombreCompleto: e.nombreCompleto,
            id: e.id,
            uuid: e.uuid,
            rol: e.rol,
          }) 
         }
        });
      });
    },
  },
  mounted(){
    this.getOperadores()
    this.getAgentes()
  }
};
</script>
